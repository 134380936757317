<template>
  <div>
    <label :for="slug" class="visuallyhidden"
      >{{ id }} quantity: {{ quantity }}</label
    >
    <input
      :id="slug"
      v-model="currentQuantity"
      type="text"
      name="quantity"
      :disabled="!available || disabled"
      @blur="validateQuantityInput()"
      @keydown="typeQuantity($event)"
      @focus="currentQuantity = null"
    />
  </div>
</template>

<script>
import { slugify } from '~/../common/utils/slugify'
export default {
  name: 'QuantityWidget',
  props: {
    quantity: {
      type: Number,
      required: true,
    },
    quantityLimit: {
      type: Number,
      required: false,
      default: null,
    },
    available: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    id: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      currentQuantity: this.quantity,
    }
  },
  computed: {
    slug() {
      return slugify(this.id)
    },
  },
  watch: {
    quantity() {
      this.currentQuantity = this.quantity
    },
  },
  methods: {
    focus() {
      this.currentQuantity = null
      this.$emit('clear-errors')
    },
    setQuantity(qty) {
      this.$emit('set-quantity', qty)
      this.currentQuantity = qty
    },
    validateQuantityInput() {
      const qty = parseInt(this.currentQuantity)
      if (isNaN(qty)) {
        this.setQuantity(this.quantity)
      } else if (this.quantityLimit && qty > this.quantityLimit) {
        this.setQuantity(this.quantityLimit)
      } else if (qty < 1) {
        this.setQuantity(1)
      } else {
        this.setQuantity(qty)
      }
    },
    typeQuantity(event) {
      this.$emit('clear-errors')
      // prevent non numbers from being input
      if (
        event.keyCode > 31 &&
        (event.keyCode < 48 || event.keyCode > 57) &&
        event.keyCode !== 9
      ) {
        event.preventDefault()
      } else if (event.keyCode === 13) {
        this.validateQuantityInput()
        if (event.keyCode === 13) {
          // blur the input box if enter key pressed
          event.target.blur()
        }
      }
    },
  },
}
</script>

<style></style>
